import { makeStyles } from "components/providers/makeStyles";
import { useTranslate } from "components/providers/LocalizationProvider";
import { Autocomplete, CircularProgress, Paper, TextField, Typography } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { Option } from "./Option";
import { useState } from "react";
import { Clear } from "@mui/icons-material";

export const filter = createFilterOptions({ trim: true });
export const useLoadingText = () => useTranslate("Loading…");

interface SearchSelectProps {
    field: {
        options?: any[];
        loading?: boolean;
        disabled?: any;
        inputRef?: any;
        noOptions?: { message: string; messageValues?: any };
        placeholder?: string;
        autocompleteProps?: any;
        inputProps?: any;
    };
    input: {
        value: any;
        onChange: (value: any) => void;
    };
    hasError?: boolean;
    filterOptions?: (options: any[], state: any) => any[];
    ListboxComponent?: React.FC<any>;
    handleHomeEndKeys?: boolean;
    freeSolo?: boolean;
}

export const SearchSelect: React.FC<SearchSelectProps> = ({ field, input, hasError, filterOptions = filter, ListboxComponent, ...rest }) => {
    const isOptionEqualToValue = (option: any, { value } : { value: any }) => option.value?.toString() === value?.toString();
    const [selectedOption, setSelectedOption] = useState();

    const noOptionsText = useTranslate(field.noOptions?.message || "No options", field.noOptions?.messageValues);
    const loadingText = useLoadingText();
    const placeholderText = useTranslate(field.placeholder || "");

    const onKeyDown = (e: React.KeyboardEvent & { defaultMuiPrevented?: boolean }) => {
        if (e.key === "Enter" && !selectedOption) {
            e.defaultMuiPrevented = true;
            e.preventDefault();
        }
    };

    return (
        <Autocomplete
            autoHighlight
            openOnFocus
            options={field.options}
            isOptionEqualToValue={isOptionEqualToValue}
            value={input.value}
            onChange={(e, value) => input.onChange(value)}
            loading={field.loading}
            clearIcon={field.loading ? <CircularProgress color="inherit" size={20} /> : <Clear />}
            loadingText={loadingText}
            noOptionsText={noOptionsText}
            onKeyDown={onKeyDown}
            onHighlightChange={(e, option) => setSelectedOption(option as any)}
            renderInput={params =>
                <TextField
                    id={params.id}
                    fullWidth={params.fullWidth}
                    error={hasError} placeholder={placeholderText} inputRef={field.inputRef}
                    slotProps={{
                        input: params.InputProps,
                        htmlInput: { ...params.inputProps, ...field.inputProps }
                    }}/>
            }
            filterOptions={filterOptions}
            disabled={field.disabled}
            getOptionDisabled={(option: any) => !!option.disabled}
            renderOption={Option}
            {...rest}
            slots={{
                paper: PaperComponent,
            }}
            slotProps={{
                listbox: { component: ListboxComponent }
            }}
            {...field.autocompleteProps}
        />
    );
};

const usePaperStyles = makeStyles(theme => ({
    label: {
        margin: theme.spacing(2, 2, 0),
        textTransform: "uppercase",
        opacity: 0.5
    },
}));

interface PaperComponentProps {
    headingText?: string;
    footer?: React.ReactNode;
}

export const PaperComponent: React.FCWithChildren<PaperComponentProps> = ({ children, headingText = "Type or select", footer, ...props }) => {
    const classes = usePaperStyles();
    const heading = useTranslate(headingText);

    return (
        <Paper elevation={2} {...props}>
            <Typography className={classes.label} variant="label2" color="textSecondary" component="div">
                {heading}
            </Typography>
            {children}
            {footer}
        </Paper>
    );
};
