import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { FormField } from "../layout/forms/FormField";
import { ISO_DATE } from "utils/dates";
import { Control, useController, UseFormGetValues, UseFormSetValue, UseFormTrigger, UseFormWatch } from "react-hook-form";
import { useState } from "react";
import { Schema } from "yup";

const identity = (v: any) => v;
const defaultFormat = (value: any) => value != null ? value : "";

dayjs.extend(utc);

export const createValidateField = (schema: Schema, setValidating?: (validating: boolean) => void ) =>
    (value: any) => {
        setValidating?.(true);
        return schema.validate(value)
            .then(() => undefined)
            .catch(e => e.message)
            .then(message => {
                setValidating?.(false);
                return message;
            });
    };

export type FieldOption = { label: string, value: any }

export interface BaseFieldProps {
    name: string;
    type?: string;
    required?: boolean;
    validation?: Schema;
    format?: (value: any, field: BaseFieldProps) => any;
    parse?: (value: { value: any, label?: string } | any, field: BaseFieldProps) => any;
    onChange?: (args: { parsedValue: any, getValues: UseFormGetValues<any>, setValue: UseFormSetValue<any>, trigger: UseFormTrigger<any> }) => void;
    options?: FieldOption[];
    autocompleteProps?: any;
    label?: React.ReactNode | string;
    help?: React.ReactNode | string;
    renderIf?: (watch: UseFormWatch<any>) => boolean;
    disabled?: boolean | (({ watch }: { watch: UseFormWatch<any> }) => boolean);
    inputRef?: React.RefObject<any>;
    displayErrorMessage?: boolean;
    inputProps?: any;
    width?: number;
    helpTooltip?: React.ReactNode | string;
    restricted?: boolean;
    contactEmail?: string;
    checkingMessage?: string;
    validMessage?: string;
    component?: React.ComponentType<any>;
    hideClearButton?: boolean;
    placeholder?: string;
    dateField?: string;
    infoMessage?: string;
    startAdornment?: React.ReactNode | string;
    [key: string]: any;
}

export interface CustomFieldProps {
    component: React.ComponentType<any>;
    renderIf?: (watch: UseFormWatch<any>) => boolean;
    [key: string]: any;
}

export interface FieldWithOptionsProps extends BaseFieldProps {
    options: FieldOption[];
}

interface FieldsetProps extends BaseFieldProps {
    type: "fieldset";
    fields: (BaseFieldProps | FieldWithOptionsProps | CustomFieldProps)[];
    renderIf?: (watch: UseFormWatch<any>) => boolean;
}

export type FieldProps = BaseFieldProps | FieldWithOptionsProps | CustomFieldProps | FieldsetProps;

interface ExtraProps {
    format?: (value: any, field: BaseFieldProps) => any;
    parse?: (value: any, field: BaseFieldProps) => any;
}

export const parseOptionToValue = (value: any, field: BaseFieldProps) => field.autocompleteProps?.multiple
    ? value?.map((o: any) => typeof o === "string" ? o : o.value)
    : value?.value;
export const formatValueToOption = (value: any, field: BaseFieldProps) => field.autocompleteProps?.multiple
    ? (value ? value.map((value: any) => field.options?.find(o => o.value === value) || { label: value, value }) : [])
    : (field.options?.find(o => o.value === value) || null);
export const formatValueToOptionWithNewOption = (value: any, field: BaseFieldProps) => field.autocompleteProps?.multiple
    ? (value ? value.map((value: any) => field.options?.find(o => o.value === value) || { label: value, value }) : [])
    : (field.options?.find(o => o.value === value) || (value ? { label: value.toString(), value } : null));
export const extraPropsForFieldType: Record<string, ExtraProps> = {
    date: {
        format: (value: any) => {
            if (value) {
                const date = new Date(value);
                const timezoneOffset = date.getTimezoneOffset() * 60000;
                return new Date(date.getTime() + timezoneOffset);
            }
            return "";
        },
        parse: (value: any) =>  value ? dayjs(value).format(ISO_DATE) : ""
    },
    radio: {
        parse: (value: any) => value === "true"
    },
    searchSelect: {
        parse: parseOptionToValue,
        format: formatValueToOption
    },
    creatableSearchSelect: {
        parse: parseOptionToValue,
        format: formatValueToOptionWithNewOption
    }
};

interface FieldComponentProps {
    field: BaseFieldProps | FieldWithOptionsProps;
    control: Control;
    getValues: UseFormGetValues<any>;
    setValue: UseFormSetValue<any>;
    trigger: UseFormTrigger<any>;
}

export const useField = ({ field, control, getValues, setValue, trigger } : FieldComponentProps) => {
    const [validating, setValidating] = useState(false);
    const { field: inputField, fieldState } = useController({
        control,
        name: field.name,
        rules: field.validation && { validate: createValidateField(field.validation, setValidating) }
    });
    const value = (field.format || (field.type && extraPropsForFieldType[field.type]?.format) || defaultFormat)(inputField.value, field);

    const input = {
        ...inputField,
        value,
        onChange: (value: any) => {
            const eventValue = value?.target ? field.type === "checkbox" ? value.target.checked : value.target?.value : value;
            const parsedValue = (field.parse || (field.type && extraPropsForFieldType[field.type]?.parse) || identity)(eventValue, field);
            inputField.onChange(parsedValue);
            field.onChange?.({ parsedValue, getValues, setValue, trigger });
        }
    };

    return { input, fieldState, validating };
};

export const Field: React.FCWithChildren<FieldComponentProps> = ({ field, control, getValues, setValue, trigger, children }) => {
    const { input, fieldState, validating } = useField({ field, control, getValues, setValue, trigger });

    return <FormField field={field} input={input} error={fieldState.error} isDirty={fieldState.isDirty} validating={validating} children={children}/>;
};
